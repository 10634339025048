<template>
  <div class="app" :class="{ no_scroll: this.bodyScroll() }">
    <div class="nav">
      <router-link to="/">
        <svg xmlns="http://www.w3.org/2000/svg" width="44.017" height="38.749" viewBox="0 0 44.017 38.749">
  <path id="Path_7359" data-name="Path 7359" d="M-910.54,7626.737a4.793,4.793,0,0,1-2.251-2.932,4.791,4.791,0,0,1,.48-3.657,4.83,4.83,0,0,1,6.59-1.771,4.524,4.524,0,0,0,3.448.453,4.554,4.554,0,0,0,3.216-5.57,4.552,4.552,0,0,0-5.57-3.217,4.5,4.5,0,0,0-2.428,1.619,14.166,14.166,0,0,1-8.338,6.146,14.213,14.213,0,0,1-17.389-10.039,14.214,14.214,0,0,1,10.04-17.389,14.1,14.1,0,0,1,10.764,1.414,4.786,4.786,0,0,1,2.251,2.931,4.79,4.79,0,0,1-.48,3.657,4.794,4.794,0,0,1-2.928,2.251,4.8,4.8,0,0,1-3.662-.479,4.52,4.52,0,0,0-3.448-.453,4.553,4.553,0,0,0-3.216,5.57,4.554,4.554,0,0,0,5.57,3.217,4.5,4.5,0,0,0,2.428-1.62,14.172,14.172,0,0,1,8.338-6.146,14.214,14.214,0,0,1,17.389,10.039,14.213,14.213,0,0,1-10.039,17.389,14.259,14.259,0,0,1-3.7.49A14.112,14.112,0,0,1-910.54,7626.737Z" transform="translate(933.268 -7589.891)" fill="#fff"/>
</svg>

      </router-link>
      <a
        target="_blank"
        href="https://instagram.com/symp.me"
        class="insta_link"
      >
        Folg uns auf Insta!
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32.998"
          height="33.002"
          viewBox="0 0 32.998 33.002"
        >
          <path
            id="Path_1466"
            data-name="Path 1466"
            d="M21477-3881a9.009,9.009,0,0,1-9-9v-15a9.01,9.01,0,0,1,9-9.006h15a9.011,9.011,0,0,1,9,9.006v15a9.01,9.01,0,0,1-9,9Zm-6-24v15a6.007,6.007,0,0,0,6,6h15a6.009,6.009,0,0,0,6-6v-15a6.01,6.01,0,0,0-6-6.006h-15A6.009,6.009,0,0,0,21471-3905Zm8.266,12.735a7.484,7.484,0,0,1-1.4-8.672,7.492,7.492,0,0,1,2.773-3.027,7.535,7.535,0,0,1,3.922-1.106,7.512,7.512,0,0,1,1.1.086,7.392,7.392,0,0,1,4.2,2.115,7.445,7.445,0,0,1,2.115,4.2,7.484,7.484,0,0,1-4.047,7.8,7.537,7.537,0,0,1-3.367.8A7.432,7.432,0,0,1,21479.264-3892.261Zm1.279-7.323a4.473,4.473,0,0,0,.842,5.2,4.456,4.456,0,0,0,3.184,1.317,4.459,4.459,0,0,0,2.018-.48,4.489,4.489,0,0,0,2.43-4.678,4.425,4.425,0,0,0-1.27-2.52,4.437,4.437,0,0,0-2.52-1.268,4.549,4.549,0,0,0-.664-.049A4.471,4.471,0,0,0,21480.543-3899.584ZM21491.5-3906a1.5,1.5,0,0,1,1.5-1.5,1.5,1.5,0,0,1,1.5,1.5,1.5,1.5,0,0,1-1.5,1.5A1.5,1.5,0,0,1,21491.5-3906Z"
            transform="translate(-21468.002 3914.002)"
            fill="#fff"
          />
        </svg>
      </a>
    </div>
    <router-view id="router-view" v-on:event="handleEvent($event)" />
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  methods: {
    //Prevent body from being scrollable in certain cases
    bodyScroll() {
      if (this.showNav) {
        return true;
      }
      return false;
    },

    //Open and close Navigation in mobile view
    openNav() {
      this.showNav = true;
      console.log("Nav open");
    },
    closeNav() {
      this.showNav = false;
      console.log("Nav closed");
    },
  },
  mounted() {
    console.log(this.$route.query.producthunter);
    if (this.$route.query.producthunter === "true") {
      setTimeout(() => this.openGreeting(), 500);
    }
  },
  data() {
    return {
      showNav: false,
    };
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
}
body {
  background-color: #2a22f6;
}

/* @font-face {
  font-family: "Inferno";
  src: url("assets/fonts/Cera Pro Light.otf");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Inferno";
  src: url("assets/fonts/Cera Pro Medium.otf");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Inferno";
  src: url("assets/fonts/Cera Pro Bold.otf");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Inferno";
  src: url("assets/fonts/Cera Pro Black.otf");
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: "Inferno";
  src: url("assets/fonts/Cera Pro Black Italic.otf");
  font-style: italic;
  font-weight: 900;
}

* {
  color: #fff;
  letter-spacing: -0.3px;
} */

.app {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  color: #fff;
  overflow-x: hidden;
  position: relative;
}

.nav {
  margin: 0 auto;
  padding: 0 0;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100px;
  display: flex;
  align-items: center;
  max-width: 1232px;
}

.nav a {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  font-size: 14px;
  align-items: center;
}

.nav a svg {
  margin-left: 16px;
}

#router-view {
  width: 100%;
  height: 100%;
}

.heading {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  font-size: 90px;
}

.subheading {
  font-size: 70px;
  color: #fff;
  font-weight: 700;
  text-align: left;
}

.section-heading {
  font-size: 48px;
  color: #fff;
  font-weight: 700;
  text-align: left;
}

.no_scroll {
  height: 100vh;
  overflow-y: hidden;
}
.text {
  color: #fff;
  font-weight: 300;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 1.5;
  text-align: left;
}

#nav {
  padding: 45px 45px;
  position: absolute;
  z-index: 10000;
  border: 1px solid pink;
  top: 0;
  right: 0;
  left: 0;
  max-width: 1332px;
  margin: 0 auto 24px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

#nav {
  margin-top: 3px;
}

.nav-icon {
  display: none;
  cursor: pointer;
  z-index: 10000000000;
}

.nav-close-icon {
  display: none;
  cursor: pointer;
  z-index: 10000000000;
}

.links {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-left: 5%;
}

.links-left {
  height: 100%;
  display: flex;
  align-items: center;
}

.links-left a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  margin: 0 40px;
  transition: 0.1s ease-in;
}

.links-left a:hover {
  color: #fff;
  transition: 0.1s ease-out;
}

.links-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.links-right a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  margin: 0;
}
.links-right a:hover {
  color: #fff;
  transition: 0.1s ease-out;
}
#login-button {
  margin-right: 50px;
  display: flex;
  align-items: center;
}

#login-button svg {
  margin-left: 20px;
}

@media screen and (max-width: 1020px) {
  .nav-icon {
    display: block;
  }

  .nav_icon_opened {
    display: none;
  }

  .nav_icon_close_opened {
    display: block;
  }

  .links {
    padding: 45px 45px 80px 45px;
    width: 100vw;
    /*height: calc(100vh - 100px);*/
    height: 100vh;
    min-height: 300px;
    position: absolute;
    z-index: 100000;
    box-sizing: border-box;
    top: 0px;
    right: 0;
    transform: translateX(100%);
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    transition: 0.2s ease-in;
    background-color: #131618f8;
  }
  .links-left {
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: initial;
    margin-top: 100px;
  }

  .links-left a {
    margin: 40px 0px 40px 0;
    font-size: 30px;
    text-align: center;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
  }

  .links-right {
    width: 100%;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0;
    display: none;
  }

  .links-right a {
    text-decoration: none;
    width: 100%;
    margin: 50px 0;
    padding: 0;
  }

  .nav_open {
    transform: translateX(0);
    transition: 0.2s ease-out;
  }

  #login-button {
    width: 100%;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 0;
  }

  #login-button a {
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 700px) {
}

@media screen and (max-width: 600px) {
  .nav {
    padding: 25px 25px 45px 25px;
  }
  .links {
    padding: 45px 25px 60px 25px;
  }
  .title {
    font-size: 38px;
    line-height: 1.5;
  }

  .subtitle {
    font-size: 35px;
    line-height: 1.5;
    text-align: left;
  }

  .text {
    color: #4c5d77;
    font-weight: 300;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    line-height: 1.5;
    text-align: left;
  }
  .links-left a {
    margin: 30px 0px 30px 0;
    font-size: 20px;
    text-align: left;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
  }
  .popup_signup_wrapper {
    padding: 0px;
  }
  .popup_login_wrapper {
    padding: 0px;
  }
}

@media screen and (max-height: 400px) {
}
</style>
