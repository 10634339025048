<template>
  <div class="phonenumber">
    <div class="header">Die Beta ist aktuell voll 😭</div>
    <div class="subheading">
      Gib deine Telefonnummer ein und wir schreiben dir, sobald Plätze frei
      werden.
    </div>
    <input type="text" v-model="phoneNumber" />
    <div class="error">
      {{ error }}
    </div>
    <div v-on:click="onSubmit()" to="/beta" class="beta_link_wrapper">
      Abschicken
    </div>
  </div>
</template>

<script>
import { addPhoneNumber } from "../db";

export default {
  name: "PhoneNumber",

  methods: {
    async onSubmit() {
      if (!this.validateForm()) {
        this.error = "Ungültige Telefonnummer";
        return;
      }
      addPhoneNumber(this.phoneNumber);
      this.$router.push("/pre-signup");
    },
    validateForm() {
      return this.phoneNumber.length > 4;
    },
  },
  data() {
    return {
      phoneNumber: "+49 ",
      error: "",
    };
  },
};
</script>

<style scoped>
.phonenumber {
  max-width: 1332px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 32px 45px;
  box-sizing: border-box;
}
.beta_link_wrapper {
  margin-top: 48px;
  background-color: white;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  cursor: pointer;
  text-decoration: none;
  color: #16191c;
  font-weight: 800;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 18px 48px 18px 48px;
  border-radius: 200px;
  font-size: 18px;
  transition: 0.2s ease-in-out;
}

.disabled {
  opacity: 0.3;
}

.beta_link_wrapper:hover {
  transform: scale(1.05);
  transition: 0.2s ease-in-out;
}
.logo {
  width: 100%;
}
.header {
  font-size: 32px;
  margin-top: 100px;
  font-weight: 700;
  margin-bottom: 16px;
  font-style: italic;
}

.subheading {
  font-weight: 800;
  font-style: italic;
  max-width: 400px;
  font-size: 17px;
  color: #c19aff;
  letter-spacing: 0px;
  text-align: center;
}

input {
  margin-top: 32px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  padding: 15px 20px 15px 20px;
  outline: none;
  background-color: transparent;
  transition: 0.25s ease-out;
  font-family: Arial, Helvetica, sans-serif;
  border: none;
  color: #fff;
  font-size: 28px;
  letter-spacing: 0.5px;
  font-weight: 400;
  border-bottom: 2px solid #fff;
}
input::-webkit-input-placeholder {
  font-family: Arial, Helvetica, sans-serif;
  color: #91a3aa;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
}
input::-moz-placeholder {
  font-family: Arial, Helvetica, sans-serif;
  color: #91a3aa;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
}
input:-ms-input-placeholder {
  font-family: Arial, Helvetica, sans-serif;
  color: #91a3aa;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
}
input:-moz-placeholder {
  font-family: Arial, Helvetica, sans-serif;
  color: #91a3aa;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
}
input[type="password"] {
  letter-spacing: 4px;
}

.error {
  color: #ff5d73;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  line-height: 30px;
  transition: 0.2s ease-in;
  height: 30px;
  line-height: 30px;
  margin-top: 16px;
}

.login-error {
  line-height: 30px;
  height: 30px;
  text-align: center;
  width: 100%;
  display: block;
}

.disabled {
  opacity: 0.4;
  cursor: default;
  pointer-events: none;
}

.disabled * {
  cursor: default;
  pointer-events: none;
}
</style>
